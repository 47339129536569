import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useForm } from "react-hook-form"
import "../styles/contact-forms.scss"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default ({ location }) => {
    const { contactEmail } = useStaticQuery(graphql`
    query PlayerSignUpQuery {
        contactEmail: allFile(filter: {sourceInstanceName: {eq: "siteOptions"}}) {
          edges {
            node {
              childLeagueJson {
                contactEmail
              }
            }
          }
        }
      }

    `)

    const emailTo = contactEmail.edges[0].node.childLeagueJson.contactEmail

    const [positions, setPositions] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formSuccess, setFormSuccess] = useState(false)

    const { register, handleSubmit, errors } = useForm()

    useEffect(() => {
        if (formSuccess) navigate("/thank-you")
    }, [formSuccess])

    const handlePositionSelection = (e) => {
        if (e.target.checked) {
            setPositions([...positions, e.target.value])
        } else {
            let newPositions = positions
            const removalIndex = positions.indexOf(e.target.value)
            newPositions.splice(removalIndex, 1)
            setPositions(newPositions)
        }
    }

    const onSubmit = formData => {
        formData.positions = positions
        formData.contactEmail = emailTo

        setIsSubmitting(true)

        fetch("/.netlify/functions/api/player-sign-up", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then(res => res.json())
            .then(res => {
                setIsSubmitting(false)
                setFormSuccess(true)
            })
    }

    const errorBorderStyles = {
        borderColor: "#DC202F",
        borderLeftWidth: "15px"
    }

    return (
        <Layout location={location} cssPageName="player-contact">
            <SEO title="Join the USWL" />
            <h1 className="title">Player Sign Up</h1>
            <form className="player-contact-form" onSubmit={handleSubmit(onSubmit)}>
                <label className="form-label" htmlFor="name">Name
                    <input type="text" name="name" ref={register({ required: true, maxLength: 80 })} style={errors.name && errorBorderStyles} />
                </label>
                <label className="form-label" htmlFor="email">Email
                    <input type="text" name="email" ref={register({ required: true, pattern: /^\S+@\S+$/i })} style={errors.email && errorBorderStyles} />
                </label>
                <label className="form-label" htmlFor="mobileNumber">Mobile Number
                    <input type="tel" name="mobileNumber" ref={register({ required: true, minLength: 6, maxLength: 12 })} style={errors.mobileNumber && errorBorderStyles} />
                </label>
                <label className="form-label" htmlFor="birthdate">Birthdate
                    <input className="birthdate" type="date" name="birthdate" ref={register({ required: true })} style={errors.birthdate && errorBorderStyles} />
                </label>
                <fieldset className="positions-group">
                    <legend>Position(s)</legend>
                    <div className="checkbox-flex">
                        <label className="checkbox-label" htmlFor="P">P
                            <input type="checkbox" className="checkbox" name="P" value="P" onClick={handlePositionSelection} />
                        </label>
                        <label className="checkbox-label" htmlFor="C">C
                            <input type="checkbox" className="checkbox" name="C" value="C" onClick={handlePositionSelection} />
                        </label>
                        <label className="checkbox-label" htmlFor="1B">1B
                            <input type="checkbox" className="checkbox" name="1B" value="1B" onClick={handlePositionSelection} />
                        </label>
                        <label className="checkbox-label" htmlFor="2B">2B
                            <input type="checkbox" className="checkbox" name="2B" value="2B" onClick={handlePositionSelection} />
                        </label>
                        <label className="checkbox-label" htmlFor="3B">3B
                            <input type="checkbox" className="checkbox" name="3B" value="3B" onClick={handlePositionSelection} />
                        </label>
                        <label className="checkbox-label" htmlFor="SS">SS
                            <input type="checkbox" className="checkbox" name="SS" value="SS" onClick={handlePositionSelection} />
                        </label>
                        <label className="checkbox-label" htmlFor="LF">LF
                            <input type="checkbox" className="checkbox" name="LF" value="LF" onClick={handlePositionSelection} />
                        </label>
                        <label className="checkbox-label" htmlFor="CF">CF
                            <input type="checkbox" className="checkbox" name="CF" value="CF" onClick={handlePositionSelection} />
                        </label>
                        <label className="checkbox-label" htmlFor="RF">RF
                            <input type="checkbox" className="checkbox" name="RF" value="RF" onClick={handlePositionSelection} />
                        </label>
                    </div>
                </fieldset>
                <label className="form-label" htmlFor="lastLevelPlayedAt">Last Level Played At
                    <input type="text" name="lastLevelPlayedAt" ref={register({ required: true })} style={errors.lastLevelPlayedAt && errorBorderStyles} />
                </label>
                <label className="form-label" htmlFor="message">Anything else you'd like to tell us?
                    <input className="textarea" type="textarea" name="message" ref={register} />
                </label>
                <div className="button-container">
                    <input className="submission-button" type="submit" value={isSubmitting ? "..." : "Send"} disabled={isSubmitting} />
                </div>
            </form>
        </Layout>
    )
}